import React, { useState } from 'react';

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"

import pdf from "../images/icons/pdf.svg"

const CoursTheorique = ({ data }) => {

  const lessonCount = Array.from(Array(9).keys()).map(i => i + 1);

  /**
   * Format date for display
   * @param {string} date
   * @returns {string} date formatted
   */
  const formatDate = (date) => {
    if (!date) return ''

    return new Date(date).toLocaleDateString('fr', {
      weekday: "short",
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const [isDownloading, setIsDownloading] = useState(false);
  /**
   * Download the program as PDF
   * @param {string} name
   */
  const download = (name) => {
    setIsDownloading(true);
    var source = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
      </head>
      <body>
        ${document.getElementById("printable").innerHTML}
      </body>
    </html>
    `;

    window.html2pdf().set({
      margin:       0,
      filename:     name + '.pdf',
      image:        { type: 'png' },
      html2canvas:  { useCORS: true },
      jsPDF:        { unit: 'in', format: 'A4', orientation: 'p' }
    }).from(source).save().then(() => {
      setIsDownloading(false);
    })
  }

  /**
   * Return the class to apply to cells depending on the date of the lesson
   * @param {string} date
   * @returns {string} class names
   */
  const getClass = (date) => {
    const today = new Date();
    date = new Date(date);

    if (date.getTime() < today.getTime()) return 'opacity-50'; // done

    if (today.getFullYear() === date.getFullYear() && // today
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()) return 'text-primary';

    return ''; // future
  }


  return (
    <Layout classes="blog-post">
      <Seo title={`${data.strapiCoursTheoriques.title} - Cours théoriques`} breadcrumb={[
        { url: "les-horaires", name: "Les horaires"},
        { url: "cours-theoriques", name: "Cours théoriques"},
        { url: data.strapiCoursTheoriques.slug, name: data.strapiCoursTheoriques.title},
      ]}  />
      <Breadcrumb paths={[
        { url: '/les-horaires/', name: "Les horaires" },
        { url: '/les-horaires/cours-theoriques/', name: "Cours théoriques" },
        { url: null, name: data.strapiCoursTheoriques.title }
      ]} />

      <h1 className="h1">{data.strapiCoursTheoriques.title}</h1>

      <ul>
        {lessonCount.map(i => (<li><span className="font-bold">Cours {i}</span> : {data.strapiCoursTheoriquesEnTete['lesson' + i]}</li>))}
      </ul>

      <div
        className="mt-12 text-center html-content"
        dangerouslySetInnerHTML={{
          __html: data.strapiCoursTheoriquesEnTete.content,
        }}
      ></div>
      <div className="overflow-x-scroll">
        <table className="border-collapse mt-8">
          {lessonCount.map(i => (
            <tr className="tr">
              <td className="td gradient-dark">
                <span className="font-bold">Cours {i}</span><br />
                <span className="text-sm">{data.strapiCoursTheoriquesEnTete['lesson'+i]}</span>
              </td>
              <td className={`td capitalize ${getClass(data.strapiCoursTheoriques['lesson'+i].date1)}`}>{formatDate(data.strapiCoursTheoriques['lesson'+i].date1)}</td>
              <td className={`td capitalize ${getClass(data.strapiCoursTheoriques['lesson'+i].date2)}`}>{formatDate(data.strapiCoursTheoriques['lesson'+i].date2)}</td>
              <td className={`td capitalize ${getClass(data.strapiCoursTheoriques['lesson'+i].date3)}`}>{formatDate(data.strapiCoursTheoriques['lesson'+i].date3)}</td>
            </tr>
          ))}
        </table>
      </div>

      {/* PRINT */}
      <div id="printable" className="hidden">
        <script src="/html2pdf.bundle.min.js"></script>
        <div className="text-dark relative text-sm p-12">
          <img
            src='/logo-yvan-blin.png'
            width={200}
            className="absolute left-6 top-0"
            alt=""
          />
          <span className="absolute right-12">Mois de {data.strapiCoursTheoriques.title}</span>
          <div className="text-center mt-8">
            <p className="font-bold uppercase text-4xl mt-16 mb-10">Programme théorique</p>
          </div>
          <table className="border border-collapse w-full">
            {lessonCount.map(i => (
              <tr>
                <td className="font-bold td-print text-center uppercase">Cours {i}</td>
                <td className="td-print">{data.strapiCoursTheoriquesEnTete['lesson' + i]}</td>
              </tr>
            ))}
          </table>

          <div
            className="mt-8 text-center html-content"
            dangerouslySetInnerHTML={{
              __html: data.strapiCoursTheoriquesEnTete.content,
            }}
          ></div>
          <table className="border border-collapse mt-8 w-full">
            {lessonCount.map(i => (
              <tr className="">
                <td className="font-bold td-print text-center uppercase">Cours {i}</td>
                <td className="td-print capitalize">{formatDate(data.strapiCoursTheoriques['lesson'+i].date1)}</td>
                <td className="td-print capitalize">{formatDate(data.strapiCoursTheoriques['lesson'+i].date2)}</td>
                <td className="td-print capitalize">{formatDate(data.strapiCoursTheoriques['lesson'+i].date3)}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <button
        onClick={() => download(data.strapiCoursTheoriques.title)}
        className={`btn mt-12 ${isDownloading ? 'animate-pulse' : ''}`}
        disabled={isDownloading}
      >
        <img src={pdf} width="20" className="inline mr-2" alt="" />
        Télécharger en PDF
      </button>
  </Layout>)
}

export default CoursTheorique

export const query = graphql`
  query($slug: String!) {
    strapiCoursTheoriques(slug: { eq: $slug }) {
      title
      slug
      lesson1 {
        date1
        date2
        date3
      }
      lesson2 {
        date1
        date2
        date3
      }
      lesson3 {
        date1
        date2
        date3
      }
      lesson4 {
        date1
        date2
        date3
      }
      lesson5 {
        date1
        date2
        date3
      }
      lesson6 {
        date1
        date2
        date3
      }
      lesson7 {
        date1
        date2
        date3
      }
      lesson8 {
        date1
        date2
        date3
      }
      lesson9 {
        date1
        date2
        date3
      }
    }
    strapiCoursTheoriquesEnTete {
      content
      lesson1
      lesson2
      lesson3
      lesson4
      lesson5
      lesson6
      lesson7
      lesson8
      lesson9
    }
  }
`
